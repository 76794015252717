.prefixBtn {
  top: 1rem;
  position: relative;
}

.custom-select {
  font-size: 14px;
  color: #66615b;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

h1 {
  font-size: 14px;
}

.buttonPosition {
  float: right;
}

.card {
  border-radius: 6px;
  background-color: #fdfdfd;
}
.content {
  background-color: #ececec;
  margin-top: 82px !important;
  position: relative;
  top: 2rem;
}

.numbers {
  padding-left: 15px;
  padding-top: 10px;
}

.uploadBtn {
  position: relative;
}

.inputFile {
  margin-top: 18px;
}

.litegraph .dialog.settings{
  left: 102%;
  top: 0px;
}

.litegraph .dialog .property_value {
  text-align: left;
}

.disabledDiv {
  opacity: 0.4;
}